import app from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

export const firebaseConfig = {
    apiKey: "AIzaSyAHuvwaEQf36_acDMEYLaRgJwN75SRwnlc",
    authDomain: "geobooklet.firebaseapp.com",
    databaseURL: "https://geobooklet.firebaseio.com",
    projectId: "geobooklet",
    storageBucket: "geobooklet.appspot.com",
    messagingSenderId: "570431190347",
    appId: "1:570431190347:web:ef6def3d67b46304c3e4ce",
    measurementId: "G-5H69YLBXQ",
};

app.initializeApp(firebaseConfig);

const firestore = app.firestore();
const firebaseAuth = app.auth();
const functions = app.functions();

export { firestore, firebaseAuth, functions };
