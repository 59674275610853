import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import {
    AppBar,
    Button,
    IconButton,
    makeStyles,
    Toolbar,
    Typography,
    Avatar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useAuth from "../hooks/useAuth";
import { useHistory } from "react-router-dom";

// Assets
import logo from "./images/geobooklet.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
}));

interface HeaderProps {
    drawerOpen: boolean;
    onOpenDrawer: (event: SyntheticEvent) => void;
}

export default ({ onOpenDrawer, drawerOpen }: HeaderProps) => {
    const classes = useStyles();
    const { user, signout } = useAuth();
    const history = useHistory();

    return (
        <AppBar style={{ background: "white" }} position="sticky">
            <Toolbar>
                <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={onOpenDrawer}
                    edge="start"
                    className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>

                <img src={logo} height={50} alt="logo" />

                <Typography variant="h6" noWrap className={classes.title}></Typography>

                {!user ? (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            history.replace("/register");
                        }}
                    >
                        Register
                    </Button>
                ) : user && user.photoURL ? (
                    <Avatar color="inherit" src={user.photoURL} />
                ) : (
                    <div />
                )}

                {!user ? (
                    <Button
                        color="primary"
                        onClick={() => {
                            history.replace("/login");
                        }}
                    >
                        Login
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        onClick={() => {
                            signout();
                        }}
                    >
                        Sign out
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};
