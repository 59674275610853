// import { Box, Heading, Text, Divider, Spinner } from "@chakra-ui/core";
// import Axios from "axios";
// import _ from "lodash";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
    Container,
    Grid,
    Typography,
    Divider,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableHead,
    Paper,
    TableBody,
} from "@material-ui/core";
import { functions } from "../../config/firebase";

export interface User {
    name: string;
    email: string;
    isAdmin: boolean;
    isPortalTopologyEditor: boolean;
    isNetbeamHiddenInterfaceReader: boolean;
}

export type UserMap = { [uid: string]: User };

// const useFetch = (url: string, user: firebase.User | null) => {
//     const [response, setResponse] = React.useState(null);
//     const [error, setError] = React.useState(null);

//     React.useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const token = user && (await user.getIdToken());
//                 if (!_.isNull(token)) {
//                     const response = await Axios.get(url, {
//                         headers: {
//                             "Content-Type": "application/json",
//                             Authorization: `Bearer ${token}`,
//                         },
//                     });
//                     setResponse(response.data);
//                 }
//             } catch (error) {
//                 setError(error);
//             }
//         };
//         fetchData();
//     }, [url, user]);
//     return { response, error };
// };

// interface Interface {
//     name: string;
//     traffic: number;
// }

// interface InterfaceTableProps {
//     list?: Interface[];
// }

// const InterfaceTable = ({ list }: InterfaceTableProps) => {
//     if (_.isUndefined(list) || _.isNull(list) || list.length === 0) {
//         return <div>No interfaces to display</div>;
//     } else {
//         const cellStyle = {
//             borderBottom: "#d0cfcf",
//             borderBottomStyle: "solid" as "solid",
//             borderBottomWidth: 1,
//         };
//         const rows = list.map((iface) => {
//             return (
//                 <tr key={iface.name}>
//                     <td style={{ background: "#DFDFDF", padding: 10, ...cellStyle }} width={400}>
//                         {iface.name}
//                     </td>
//                     <td style={{ background: "#c3c2c2", padding: 10, paddingRight: 20 }}>
//                         {iface.traffic}
//                     </td>
//                 </tr>
//             );
//         });

//         return (
//             <table>
//                 <tbody>{rows}</tbody>
//             </table>
//         );
//     }
// };

const getStravaActivityList = async () => {
    const stravaActivities = functions.httpsCallable("stravaActivities");
    return await stravaActivities();
};

export default () => {
    const { user } = useAuth();
    const [activities, setActivities] = useState([]);

    // If a "code" is supplied in the url then this is strava calling us back. In
    // that case we want to send the code to our cloud function so that it can
    // be exchanged for an access token and that placed into the database (firestore)
    useEffect(() => {
        if (user) {
            getStravaActivityList().then((result) => {
                console.log(result.data.activities);
                setActivities(result.data.activities);
            });
        }
    }, [user]);

    const toTimeString = (seconds: number) =>
        new Date(seconds * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/)?.[0];

    const toDistanceString = (distance: number) => Math.floor(0.00621371 * distance) / 10;

    if (user) {
        return (
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3">Activities</Typography>
                        <p />
                        <Typography>Here are your most recent Strava activities</Typography>
                        <Divider variant="fullWidth" style={{ marginBottom: 10 }} />

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>
                                            <b>Activity</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Distance</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Moving time</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {activities && activities.length > 0 ? (
                                    <TableBody>
                                        {activities.map((activity: any) => (
                                            <TableRow key="strava">
                                                <TableCell>{activity.start_date}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {activity.name}
                                                </TableCell>
                                                <TableCell>
                                                    {toDistanceString(activity.distance)}
                                                </TableCell>
                                                <TableCell>
                                                    {toTimeString(activity.moving_time)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody></TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3">Activities</Typography>
                        <p />
                        <Typography>Only logged in users may view this page</Typography>
                    </Grid>
                </Grid>
            </Container>
        );
    }
};
