import { Container, Grid, Typography, Divider } from "@material-ui/core";
import React from "react";

export default () => {
    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">About</Typography>
                    <p />
                    <Typography>Nothing to see here.</Typography>
                    <Divider variant="fullWidth" style={{ marginBottom: 10 }} />
                </Grid>
            </Grid>
        </Container>
    );
};
