import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import React from "react";
import { Formik, Form } from "formik";
import { InputField } from "../../InputField";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
// import { firestore as db } from "../../config/firebase";
import GoogleButton from "react-google-button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default () => {
    const { signin } = useAuth();
    const history = useHistory();

    const handleGoogleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    // .then((cred) => {
                    //     db.collection("users")
                    //         .doc(cred.user?.uid)
                    //         .set({
                    //             last_login: new Date(),
                    //         })
                    //         .then(() => {
                    //             console.log(
                    //                 "Set user last_login time on firestore after google signin"
                    //             );
                    //             history.push("/profile");
                    //         });
                    // })
                    .catch((e) => console.error(e.message));
            });
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={12}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={2} />
                    <Grid item md={8}>
                        <Box border={0}>
                            <Typography variant="h3">Login</Typography>
                            <p />
                            <Typography>Please login to access your special powers.</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={2} />
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={2} />
                    <Grid item md={8}>
                        <Box border={0} paddingTop={3} paddingBottom={3}>
                            <GoogleButton
                                onClick={() => {
                                    handleGoogleLogin();
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={2} />
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={2} />
                    <Grid item md={10}>
                        <Divider />
                    </Grid>
                    <Grid item md={2} />
                </Grid>

                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item md={2} />
                    <Grid item md={8}>
                        <div style={{ paddingTop: 10 }}>
                            <Typography>Or login with your email and password:</Typography>
                        </div>
                        <Box>
                            <Formik
                                initialValues={{ email: "", password: "" }}
                                onSubmit={async ({ email, password }, { setErrors }) => {
                                    const resp = await signin(email, password);
                                    console.log(resp);
                                    history.replace("/profile");
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <InputField
                                            name="email"
                                            style={{ width: 380, paddingRight: 10 }}
                                            label="Email"
                                        />
                                        <InputField
                                            name="password"
                                            label="Password"
                                            type="password"
                                        />
                                        <div style={{ padding: 10 }}></div>
                                        <Button variant="contained" color="primary" type="submit">
                                            Login
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                    <Grid item md={2} />
                </Grid>
            </Grid>
        </Grid>
    );
};
