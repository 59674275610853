import { useHistory, useLocation } from "react-router-dom";
import { useSearchParams } from "./useSearchParams";

export type NullableString = string | null | undefined;

/**
 * useQueryState() is a react hook used to get and set a single variable onto the query string of the URL.
 * the useQueryState returns a tuple like setState, e.g.
 *
 * ```
 * const [ selection, setSelection ] = useQueryState(variableName, defaultValue)
 * ```
 *
 * The source of truth for the state is on the URL's query string, using the query variable specified in
 * the first argument to the hook. The second argument is the default variable. If the value is set to
 * the default then no query variable will be included in the query string. If no variable is on the
 * query string then the default value is of course returned.
 */
export const useQueryState = (
    variableName: string,
    defaultValue?: string | null
): [NullableString, (v: NullableString) => void] => {
    const query = useSearchParams();

    const history = useHistory();
    const { pathname } = useLocation();

    const state = query.get(variableName) || defaultValue;

    const setState = (value: NullableString) => {
        const targetQuery = new URLSearchParams(query);
        if (value === undefined || value === null || value === defaultValue) {
            targetQuery.delete(variableName);
        } else {
            targetQuery.set(variableName, value);
        }
        history.push(`${pathname}?${targetQuery.toString()}`);
    };

    return [state, setState];
};
