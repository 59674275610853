import { Box, Button, Heading, Divider, Text } from "@chakra-ui/core";
import { Formik, Form } from "formik";
import React from "react";
import { InputField } from "../../InputField";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import firebase from "firebase";

export default () => {
    const { signup } = useAuth();
    const history = useHistory();

    const handleGoogleLogin = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then((result) => {
                        console.log(result);
                        history.push("/profile");
                    })
                    .catch((e) => console.error(e.message));
            });
    };

    return (
        <Box>
            <Heading mb={4}>Register</Heading>
            <Text>Good choice! Let's sign you up.</Text>

            <Divider />

            <Box py={4} verticalAlign="center">
                {" "}
                <Text fontSize="sm">
                    A Google account is the quickest and best way to register.
                </Text>
                <Button
                    mt={4}
                    type="submit"
                    variantColor="teal"
                    onClick={() => {
                        handleGoogleLogin();
                    }}
                >
                    <span>Join with Google</span>
                </Button>
            </Box>

            <Divider />

            <Box py={4}>
                <Text fontSize="sm">
                    Or, you can register with an email and password if you do not have a Google
                    account.{" "}
                </Text>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    onSubmit={async ({ email, password }, { setErrors }) => {
                        const resp = await signup(email, password);
                        console.log(resp);
                        history.replace("/profile");
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Box mt={4}>
                                <InputField name="email" placeholder="email" label="Email" />
                            </Box>
                            <Box mt={4}>
                                <InputField
                                    name="password"
                                    placeholder="password"
                                    label="Password"
                                    type="password"
                                />
                            </Box>
                            <Button
                                mt={4}
                                type="submit"
                                isLoading={isSubmitting}
                                variantColor="teal"
                            >
                                Register
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};
