import { Container, Grid, Typography, Divider, Box, Switch } from "@material-ui/core";
import { FormLabel } from "@chakra-ui/core";

import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { functions } from "../../config/firebase";
import { Selector, Option } from "../../controls/Selector";
import { useQueryState } from "../../hooks/useQueryState";
import { Flex } from "@chakra-ui/core";

const loadUserList = async () => {
    const listUsers = functions.httpsCallable("listUsers");
    return await listUsers();
};

export interface User {
    name: string;
    email: string;
    isAdmin: boolean;
    isPortalTopologyEditor: boolean;
    isNetbeamHiddenInterfaceReader: boolean;
}

export type UserMap = { [uid: string]: User };

export default () => {
    const { user, permissions } = useAuth();
    const [users, setUsers] = useState<UserMap>();
    const [selected] = useQueryState("user");

    useEffect(() => {
        loadUserList().then((users) => {
            const userMap: UserMap = {};
            users.data.forEach((u: { [key: string]: any }) => {
                userMap[u.uid] = {
                    name: u.displayName,
                    email: u.email,
                    // Custom claims
                    isAdmin: u.customClaims && !!u.customClaims.admin,
                    isPortalTopologyEditor:
                        u.customClaims && !!u.customClaims["portal:topology:editor"],
                    isNetbeamHiddenInterfaceReader:
                        u.customClaims && !!u.customClaims["netbeam:hidden-interfaces:reader"],
                };
            });

            setUsers(userMap);
        });
    });

    if (user && permissions?.admin === true) {
        const userList: Option[] = [];
        if (users) {
            Object.keys(users).forEach((uid) => {
                const userInfo = users[uid];
                userList.push({ value: uid, label: `${userInfo.email} (${userInfo.name})` });
            });
        }

        const displayUser = users && selected && users[selected];
        const selectedUser = users && selected && users[selected] ? users[selected] : null;

        return (
            <Container>
                <Grid container direction="row">
                    <Grid item md={12}>
                        <Box border={0} padding={3}>
                            <Typography variant="h3">Admin</Typography>
                            <p />
                            <Typography>
                                As an admin, you can control permissions for all users. This
                                includes making another user an Admin as well. Only another admin
                                can remove your admin permissions. Select the email of this persion
                                and user the toggles to control that user's permissions.
                            </Typography>
                            <Divider variant="fullWidth" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={6}>
                        <Box border={0} padding={3}>
                            <div
                                style={{
                                    color: "#212124",
                                    fontWeight: 200,
                                    fontSize: 14,
                                }}
                            >
                                USER
                            </div>

                            <Selector options={userList} queryVariable="user" isLoading={!users} />

                            {displayUser ? (
                                <div>
                                    <Flex justify="begin" align="center">
                                        <Switch
                                            id="admin"
                                            checked={selectedUser ? selectedUser.isAdmin : false}
                                            onChange={async (
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const state = e.target.checked;
                                                const email = selectedUser?.email;
                                                const setAdminRole = functions.httpsCallable(
                                                    "setAdminRole"
                                                );
                                                await setAdminRole({ email, state });
                                            }}
                                        />
                                        <FormLabel htmlFor="admin">Admin rights</FormLabel>
                                    </Flex>
                                    <Flex justify="begin" align="center">
                                        <Switch
                                            id="topo"
                                            checked={
                                                selectedUser
                                                    ? selectedUser.isPortalTopologyEditor
                                                    : false
                                            }
                                            onChange={async (
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const state = e.target.checked;
                                                const email = selectedUser?.email;
                                                const setPortalTopologyEditorRole = functions.httpsCallable(
                                                    "setPortalTopologyEditorRole"
                                                );
                                                await setPortalTopologyEditorRole({ email, state });
                                            }}
                                        />
                                        <FormLabel htmlFor="topo">Topology editor</FormLabel>
                                    </Flex>
                                    <Flex justify="begin" align="center">
                                        <Switch
                                            id="topo"
                                            checked={
                                                selectedUser
                                                    ? selectedUser.isNetbeamHiddenInterfaceReader
                                                    : false
                                            }
                                            onChange={async (
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const state = e.target.checked;
                                                const email = selectedUser?.email;
                                                const setNetbeamHiddenInterfacesReaderRole = functions.httpsCallable(
                                                    "setNetbeamHiddenInterfacesReaderRole"
                                                );
                                                await setNetbeamHiddenInterfacesReaderRole({
                                                    email,
                                                    state,
                                                });
                                            }}
                                        />
                                        <FormLabel flex={1} htmlFor="topo">
                                            Hidden interface viewer
                                        </FormLabel>
                                    </Flex>
                                </div>
                            ) : (
                                <div />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Container>
                <Grid container direction="row">
                    <Grid item md={12}>
                        <Box border={0} padding={3}>
                            <Typography variant="h3">Admin</Typography>
                            <p />
                            <Typography>
                                An admin needs to be logged in to use the admin console.
                            </Typography>

                            <Divider variant="fullWidth" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }
};
