import React, { InputHTMLAttributes } from "react";
import { useField } from "formik";
import { FormControl, TextField } from "@material-ui/core";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    name: string;
    textarea?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({ label, color, size: _, ...props }) => {
    const [field, { error }] = useField(props);

    return (
        <FormControl disabled={!!error}>
            <TextField
                fullWidth
                id={field.name}
                label={label}
                variant="standard"
                {...field}
                {...props}
            />
        </FormControl>
    );
};
