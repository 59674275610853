import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import ActivitiesIcon from "@material-ui/icons/DirectionsRun";
import ProfileIcon from "@material-ui/icons/AccountBox";
import { Box, Flex } from "@chakra-ui/core";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./Home";
import ActivityList from "./strava/ActivityList";
import Login from "./user/Login";
import Register from "./user/Register";
import Profile from "./user/Profile";
import Admin from "./user/Admin";
import Header from "./Header";
import useAuth from "../hooks/useAuth";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            background: "#f6f6f9",
        },
        appBar: {
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: "flex-end",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        title: {
            flexGrow: 1,
        },
    })
);

export default function App() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { user, permissions } = useAuth();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Router>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}
                    >
                        <Header onOpenDrawer={handleDrawerOpen} drawerOpen={open} />
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === "ltr" ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </div>
                        <Divider />

                        <List>
                            <ListItem button component="a" href="activities" key="activities">
                                <ListItemIcon>
                                    <ActivitiesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Activities" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            {permissions?.admin ? (
                                <ListItem button component="a" href="admin" key="admin">
                                    <ListItemIcon>
                                        <AdminIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Admin" />
                                </ListItem>
                            ) : null}

                            {user ? (
                                <ListItem button component="a" href="profile" key="profile">
                                    <ListItemIcon>
                                        <ProfileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" />
                                </ListItem>
                            ) : null}
                        </List>
                    </Drawer>
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        <Box bg="#f6f6f9" color="black" p={2} border={3}>
                            {/* <Box w="100%"> */}
                            <Flex p={0} m={0}>
                                <Flex flex={1} align="flex-start" justify="left" p={2}>
                                    <Box border={2} width={1}>
                                        <Switch>
                                            <Route exact path="/">
                                                <Home />
                                            </Route>
                                            <Route exact path="/activities">
                                                <ActivityList />
                                            </Route>
                                            <Route path="/login">
                                                <Login />
                                            </Route>
                                            <Route path="/register">
                                                <Register />
                                            </Route>
                                            <Route path="/profile">
                                                <Profile />
                                            </Route>
                                            <Route path="/admin">
                                                <Admin />
                                            </Route>
                                        </Switch>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    </main>
                </div>
            </Router>
        </div>
    );
}
