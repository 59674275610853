import React from "react";
import Select, { ValueType } from "react-select";
import _ from "lodash";
import { useQueryState } from "../hooks/useQueryState";

export interface Option {
    value?: string;
    label?: string;
}

interface SelectorProps {
    options: Option[];
    defaultChoice?: string;
    queryVariable?: string;
    isLoading?: boolean;
    placeholder?: string;
    width?: string;
}

/**
 * A control that wraps react-select, except when it changes it writes that state to the
 * query string. It has been somewhat styled to fit in to other bootstap pieces. The
 * variable that the selector will write to on the url can be customized with the
 * `queryVariable` prop, as well as the `defaultValue` being defined. The control can
 * also show a loading state while data is loading on the page.
 *
 * The `options` prop controls what is in the control. They are a list of elements, each
 * of which is of the form { value, label }. For example:
 * ```
 * const options = [
 *  { value: "chocolate", label: "Chocolate" },
 *  { value: "strawberry", label: "Strawberry" },
 *  { value: "vanilla", label: "Vanilla" },
 * ]
 * ```
 */
export const Selector = ({
    options,
    defaultChoice,
    queryVariable = "s",
    isLoading = false,
    placeholder = "Select...",
    width = "100%",
}: SelectorProps) => {
    // Manages the state of the choice on the query string
    const [value, setValue] = useQueryState(queryVariable, defaultChoice);

    // Make the drop down smaller to match Bootstrap input widgets
    const customStyles: any = {
        container: (provided: any) => ({
            ...provided,
            display: "inline-block",
            width,
            minHeight: "1px",
            textAlign: "left",
            border: "none",
            paddingBottom: 15,
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            height: "32px",
        }),
        input: (provided: any) => ({
            ...provided,
            minHeight: "1px",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            paddingTop: "0",
            paddingBottom: "0",
        }),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            height: "12px",
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            paddingTop: "0",
            paddingBottom: "0",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            height: "30px",
            paddingTop: "0",
            paddingBottom: "0",
        }),
        singleValue: (provided: any) => ({
            ...provided,
            minHeight: "1px",
            paddingBottom: "2px",
        }),
    };

    const current = _.find(options, (item: Option) => value === item.value);

    return (
        <Select
            styles={customStyles}
            value={current}
            isDisabled={false}
            isClearable={true}
            isSearchable={true}
            blurInputOnSelect={true}
            isLoading={isLoading}
            placeholder={placeholder}
            options={options}
            onChange={(v: ValueType<Option>) => {
                const value = (v as Option).value;
                setValue(value);
            }}
        />
    );
};
