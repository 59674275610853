import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import {
    Grid,
    Typography,
    Divider,
    Box,
    Container,
    Chip,
    Paper,
    Button,
    Table,
    TableHead,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Link,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import { functions, firestore } from "../../config/firebase";
import ConnectWithStrava from "../images/strava.png";
import stravaLogo from "../images/stravaLogo.png";

const exchangeStravaAuthCodeForAccessToken = async (code: string) => {
    const exchangeStravaCodeForToken = functions.httpsCallable("exchangeStravaCodeForToken");
    return await exchangeStravaCodeForToken({ code });
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default () => {
    const { user, permissions } = useAuth();
    const query = useQuery();
    const history = useHistory();

    const [userEntry, setUserEntry] = useState<any>({});

    const code = query.get("code");
    const state = query.get("state");
    // const scope = query.get("scope");

    // If a "code" is supplied in the url then this is strava calling us back. In
    // that case we want to send the code to our cloud function so that it can
    // be exchanged for an access token and that placed into the database (firestore)
    useEffect(() => {
        if (code) {
            exchangeStravaAuthCodeForAccessToken(code).then((result) => {
                history.replace("profile");
            });
        }
    }, [code]);

    console.log(user);

    // Monitor the user's firestore db entry for changes
    useEffect(() => {
        if (user) {
            firestore
                .collection("users")
                .doc(user?.uid)
                .onSnapshot((doc: any) => {
                    console.log("Current data: ", doc.data());
                    setUserEntry(doc.data());
                });
        }
    }, [user]);

    // Initial leg of connecting to strava. Here we prepare a redirect url to send the user
    // to a strava oauth authorization page. When they are done there stava will send them
    // back to this profile page, but with some extras attached to the url, with the code
    // being the main thing. This is then sent to the backend for the next step.
    const handleStravaConnect = () => {
        const clientId = `5534`;
        const base = `www.strava.com/oauth/authorize`;
        const redirect = `http://localhost:3000/profile`;
        const url = `http://${base}?state=strava&client_id=${clientId}&response_type=code&redirect_uri=${redirect}&approval_prompt=force&scope=activity:read_all`;
        window.location.assign(url);
    };

    // Determine strava connection state, which is a combination of our internal state,
    // the presents of a state=strava&code=xxx in the url, and the state of the user's
    // database entry which holds the live tokens for the connection if established.
    let stravaConnectionState = "Not connected";
    if (userEntry && userEntry.strava_user_id) {
        stravaConnectionState = "Connected";
    } else if (code && state === "strava") {
        stravaConnectionState = "Connecting...";
    }

    console.log("UserEntry", userEntry, userEntry.strava_user_id);

    if (user) {
        // Tags to display to the user additional permissions on their account. Right now
        // there is only an admin permission
        const permissionTags = [];
        if (permissions?.admin) {
            permissionTags.push(<Chip size="small" key="admin" label="admin" />);
        }

        const stravaProfileURL =
            userEntry && userEntry.strava_user_id
                ? `https://www.strava.com/athletes/${userEntry.strava_user_id}`
                : "";

        return (
            <Container>
                <Grid container direction="row">
                    <Grid item md={12}>
                        <Box border={0} padding={3}>
                            <Typography variant="h3">Profile</Typography>
                            <p />
                            <Typography>
                                This page shows information about the current user.
                            </Typography>

                            <Divider variant="fullWidth" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Box border={0} padding={0}>
                            <Box border={0} padding={3}>
                                <Image
                                    size="200px"
                                    src={user.photoURL ? user.photoURL : ""}
                                    alt={user.displayName ? user.displayName : ""}
                                />
                            </Box>
                            <Typography variant="h5">Permissions</Typography>
                            <Box border={0} padding={3}>
                                {permissionTags.length > 0 ? (
                                    permissionTags
                                ) : (
                                    <Typography>No specific permissions</Typography>
                                )}
                            </Box>

                            <Typography variant="h5">Connections</Typography>
                            <Box
                                border={0}
                                padding={3}
                                width={1.0}
                                style={{
                                    overflow: "wrap",
                                    width: "100%",
                                }}
                            >
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>
                                                    <b>Source</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Status</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Profile</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Actions</b>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key="strava">
                                                <TableCell component="th" scope="row">
                                                    <span>
                                                        <img src={stravaLogo} width={45} />
                                                    </span>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    Strava
                                                </TableCell>
                                                <TableCell>{stravaConnectionState}</TableCell>
                                                <TableCell>
                                                    <Link href={stravaProfileURL}>
                                                        {stravaProfileURL}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {stravaConnectionState === "Connected" ? (
                                                        <Button variant="outlined" color="primary">
                                                            Disconnect
                                                        </Button>
                                                    ) : (
                                                        <Button onClick={handleStravaConnect}>
                                                            <img
                                                                src={ConnectWithStrava}
                                                                alt="Connect to Strava"
                                                            />
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Typography variant="h5">User Info</Typography>
                            <Box
                                border={0}
                                padding={3}
                                width={1.0}
                                style={{
                                    overflow: "wrap",
                                    width: "100%",
                                }}
                            >
                                <Paper variant="outlined">
                                    {/* <pre>{JSON.stringify(user, null, 3)}</pre> */}
                                </Paper>
                            </Box>

                            <Typography variant="h5">JWT</Typography>
                            <Box
                                border={0}
                                padding={3}
                                width={1.0}
                                style={{
                                    overflow: "wrap",
                                    width: "100%",
                                }}
                            >
                                <Paper variant="outlined">
                                    <Typography>
                                        {JSON.stringify(user["stsTokenManager"])}
                                    </Typography>
                                </Paper>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    } else {
        return (
            <Box>
                <Typography variant="h3">Profile</Typography>
                <Typography>No user is logged in</Typography>
            </Box>
        );
    }
};
