// Hook (use-auth.js)
import { useState, useEffect } from "react";
import * as firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/database";

import { firebaseAuth } from "../config/firebase";

export interface Permissions {
    admin?: boolean;
    topologyEditor?: boolean;
    hiddenInterfaceViewer?: boolean;
}

// Provider hook that creates auth object and handles state
export default () => {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [permissions, setPermissions] = useState<Permissions>();
    const [error, setError] = useState<string | null>(null);

    const signin = async (email: string, password: string) => {
        try {
            const response = await firebaseAuth.signInWithEmailAndPassword(email, password);
            setUser(response.user);
            setError(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const signup = async (email: string, password: string) => {
        const response = await firebaseAuth.createUserWithEmailAndPassword(email, password);
        setUser(response.user);
        return response.user;
    };

    const signout = async () => {
        await firebaseAuth.signOut();
        setUser(null);
    };

    const sendPasswordResetEmail = async (email: string) => {
        await firebaseAuth.sendPasswordResetEmail(email);
        return true;
    };

    const confirmPasswordReset = async (code: string, password: string) => {
        await firebaseAuth.confirmPasswordReset(code, password);
        return true;
    };

    const token = async () => {
        const idTokenResult = await user?.getIdTokenResult();
        return idTokenResult?.token;
    };

    // Subscribe to user login status changes
    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
            // We got an updated user, which maybe either null if the logged
            // out or a user object if they logged in
            setUser(user);

            // We get the custom claims associated with this user as well
            // because the rest of the app needs to be updated with these
            // They are exposed as a curated "permissions" on the useAuth hook
            const idTokenResult = await user?.getIdTokenResult();
            const claims = idTokenResult?.claims;
            setPermissions({
                admin: claims ? claims["admin"] : false,
                topologyEditor: claims ? claims["portal:topology:editor"] : false,
                hiddenInterfaceViewer: claims ? claims["netbeam:hidden-interfaces:reader"] : false,
            });
        });

        return () => unsubscribe();
    }, []);

    // Configure FirebaseUI.
    const signinUIConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: "popup",
        // Redirect to home page after sign in is successful
        signInSuccessUrl: "/",
        // We will display the Google auth provider.
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    };

    // Return the user object and auth methods
    return {
        auth: firebaseAuth,
        user,
        permissions,
        token,
        error,
        signin,
        signinUIConfig,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
    };
};
